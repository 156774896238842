.Workshop_wrapper {
    display: flex;
    flex-direction: column;
    width: 48%;
    padding: 20px;
    color: white;
    border: 2px solid rgb(97, 219, 251);
    font-family: "SpaceMono", Arial, sans-serif;
    position: relative;
}

.Workshop {
    display: flex;
    margin-bottom: 50px;
}

.WorkshopFirstInfo {
    margin: 0 20px;
}

.WorkshopImg {
    height: 100%;
    max-height: 110px;
    object-fit: contain;
}

.WorkshopDate {
    font-size: 13px;
    opacity: 0.8;
}

.WorkshopFirstInfoName {
    font-size: 18px;
    margin-top: 20px;
}

.WorkshopLetterInfoName {
    font-size: 20px;
    color: rgb(97, 219, 251);
}

.WorkshopLetterInfo {
    max-width: 180px;
    width: 100%;
}

.WorkshopLogo {
    max-width: 130px;
}

.Workshop-btn-wrap {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 0;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
}

.WorkshopBtn {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    background-color: #61dbfb;
    min-width: 200px;
    max-width: 210px;
    width: 100%;
    height: 35px;
    margin-right: 10px;
    border: none;
    margin-top: 10px;
    border: 1px solid #61dbfb;
    transition: 1s;
    padding: 5px 0;
    text-transform: uppercase;
    text-align: center;
}

.WorkshopBtn:hover {
    color: #61dbfb;
    background-color: #000000;
    border: 1px solid #61dbfb;
}
.WorkshopImg_wrap {
    min-width: 140px;
}
@media screen and (max-width: 1400px) {
    .WorkshopBtn {
        min-width: 150px;
        max-width: 175px;
    }

    .WorkshopTitle {
        font-size: 16px;
    }

    .WorkshopLetterInfo {
        max-width: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .Workshop {
        margin-bottom: 30px;
    }

    .WorkshopImg_wrap {
        min-width: 100px;
    }

    .WorkshopFirstInfoName {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .Workshop_wrapper {
        width: 100%;
    }

    .WorkshopLetterInfo {
        max-width: 200px;
    }
}

@media screen and (max-width: 640px) {
    .WorkshopLetterInfo {
        max-width: 150px;
    }

    .Workshop_wrapper {
        padding: 10px;
    }

    .WorkshopLetterInfo {
        max-width: 90px;
    }

    .WorkshopImg {
        max-height: 90px;
    }

    .WorkshopDate {
        font-size: 10px;
    }

    .WorkshopLetterInfoName {
        font-size: 18px;
    }

    .WorkshopPosition {
        font-size: 14px;
    }

    .WorkshopTitle {
        font-size: 13px;
    }

    .WorkshopBtn {
        min-width: 80px;
        max-width: 140px;
    }

    .WorkshopLogo {
        max-width: 90px;
    }

    .Workshop {
        margin-bottom: 50px;
    }

    .WorkshopImg_wrap {
        min-width: 150px;
    }
}

@media screen and (max-width: 420px) {
    .WorkshopImg_wrap {
        min-width: 70px;
    }

    .WorkshopFirstInfoName {
        font-size: 10px;
    }

    .WorkshopDate {
        font-size: 9px;
    }

    .WorkshopFirstInfo {
        margin: 0 10px;
    }

    .WorkshopLetterInfoName {
        font-size: 16px;
    }
}
