.header {
    background-image: url('../../img/main.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.main_creative {
    top: -69px;
    position: relative;
}

.main-block {
    align-items: center;
    display: flex;
    height: calc(100% - 84px);
}

.main_block-wrap {
    display: flex;
    justify-content: space-between;
}
.background-noise {
    background-image: url('../../img/noise.gif');
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 670px;
    z-index: 1;
}
.navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px 0;
    position: relative;
    z-index: 100;
}
.navbar .logo a img {
    width: 90px;
}
.navbar .menu ul {
    display: flex;
    justify-content: flex-end;
    width: 550px;
    list-style: none;
}
.menu ul li a{
    text-decoration: none;
    color: #61dbfb;
    font-size: 16px;
    font-family: 'Righteous';
    text-transform: uppercase;
    margin-left: 40px;
}
.menu ul li:hover a{
    color: #fff;
}
.main-block {
    position: relative;
    z-index: 10;
}
.text {
    margin-top:30px;
}
.text h1 {
    color: #61dbfb;
    font-size: 60px;
    font-family: 'SpaceMono';
    line-height: 1.55;
}
.text h2 {
    color: #dbdbdb;
    font-size: 45px;
    font-family: 'SpaceMono';
    line-height: 1.15;
    font-weight: 700;
    padding-bottom: 50px;
}
/* .text h3 {
    color: #dbdbdb;
    font-size: 30px;
    font-family: 'SpaceMono',Arial,sans-serif;
    line-height: 1.15;
    font-weight: 700;
    padding-top: 23px;
} */
.text h4 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: 'SpaceMono',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    margin: 0;
}
.main_button {
    color: #000000;
    font-size: 30px;
    font-family: 'SpaceMono',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    border-width: 1px;
    border-radius: 0px;
    background-color: #61dbfb;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    transition: .4s;
    width: 280px;
    height: 60px;
    text-decoration: none;
    width: 45%;
}
.st-inline-share-buttons {
    color: #fff;
    border-width: 1px;
    border-radius: 0px;
    background-color: #000;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    transition: .4s;
    width: 280px;
    height: 60px;
    text-decoration: none;
    width: 45%;
}

.st-inline-share-buttons:hover {
    background: #fff;
}

.st-inline-share-buttons .st-btn {
    width: 100%;
    display: flex!important;
    justify-content: space-evenly;
    align-items: center;
    height: 100%!important;
}

.st-inline-share-buttons .st-label {
    color: #fff;
    font-size: 30px;
    font-family: 'SpaceMono',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    text-transform: uppercase;
    display: block!important;
}

.st-inline-share-buttons img {
    top: unset!important;
    height: initial!important;
}

.st-btn:hover {
    opacity: unset!important;
    top: unset!important;
}

.main_button:hover {
    background-color: #dbdbdb;
}
.main_copy_btn_block {
    width: 45%;
}
.main_button.main_btn--share {
    width: 100%;
    display: block;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    background: #000;
    color: #dbdbdb;
}
.main_button.main_btn--share:hover {
    color: #171616;
    background-color: #dbdbdb;
}
.text .arrow {
    -webkit-animation: scroll-down-anim cubic-bezier(0.65, 0.05, 0.36, 1) 1.7s infinite;
    -moz-animation: scroll-down-anim cubic-bezier(0.65, 0.05, 0.36, 1) 1.7s infinite;
    -o-animation: scroll-down-anim cubic-bezier(0.65, 0.05, 0.36, 1) 1.7s infinite;
    animation: scroll-down-anim cubic-bezier(0.65, 0.05, 0.36, 1) 1.7s infinite; 
    position: relative;
}
.text img {
    display: block;
    padding: 35px 0;
}

.main_btn_block {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    max-width: 700px;
}
@-webkit-keyframes scroll-down-anim {
    0%{ bottom: 0px; }
    55%{ bottom: 10px; }
    100%{ bottom: 0px; }
  }
  @-moz-keyframes scroll-down-anim {
    0%{ bottom: 0px; }
    55%{ bottom: 10px; }
    100%{ bottom: 0px; }
  }
  @-o-keyframes scroll-down-anim {
    0%{ bottom: 0px; }
    55%{ bottom: 10px; }
    100%{ bottom: 0px; }
  }
  @keyframes scroll-down-anim {
    0%{ bottom: 0px; }
    55%{ bottom: 10px; }
    100%{ bottom: 0px; }
  }
.main-block .video {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 40px;
}
.main_block-item {
    width: 50%;
}
.main-block .video video {
    max-width: 100%;
    height: auto;
}

.main_copy_btn_block {
    position: relative;
}

.main_copy_text {
    font-size: 16px;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%);
}

@media only screen and (max-width: 1300px) {
    .main_creative {
        max-width: 450px;
        display: block;
        margin: 0 auto;
    }

    .main_creative {
        top: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .text h1 {
        font-size: 48px;
    }
    .text h2 {
        font-size: 30px;
    }
    .text h3 {
        font-size: 20px;
    }
    .text h4 {
        font-size: 15px;
    }
    .main_button {
        font-size: 22px;
    }
    .st-inline-share-buttons .st-label {
        font-size: 22px;
    }
    .main_button.main_btn--share {
        padding: 12px 10px;
    }
    .main-block .video video {
        margin-top: -100px;
    }
    .background-noise {
        height: 571px;
    }
}

@media only screen and (max-width: 1001px) {
    .main_block-wrap {
        flex-direction: column;
    }

    .main-block .arrow {
        display: none;
    }

    .main_block-item {
        width: 100%;
    }

    .main-block .video {
        margin-left: 0;
    }
    .main-block .video video {
        margin-top: 30px;
    }
        
    .main-block {
        height: calc(100% - 144px);
    }
}

@media only screen and (max-width: 1001px) and (max-height: 900px) {
    .main_block-item .text {
        margin-top: 0;
    }

    .main-block .video video {
        height: 200px;
        margin: 20px auto 0;
    }

    .st-inline-share-buttons {
        height: 43px!important;
    }

    .main_button {
        height: 43px;
    }

    .text h2 {
        padding-bottom: 0;
    }
}

/* @media only screen and (max-width: 991px) {
    .text h1 {
        font-size: 60px;
    }
    .text h2 {
        font-size: 40px;
    }
    .text h3 {
        font-size: 30px;
    }
    .text h4 {
        font-size: 20px;
    }
    .text .arrow {
        display: none;
    }
    .main-block .video {
        justify-content: center;
    }
    .main-block .video video {
        margin-top: 40px;
        margin-bottom: 100px;
    }
    .background-noise {
        height: 966px;
    }
    .main_button {
        font-size: 18px;
        height: 40px;
        max-width: 300px;
    }
    .st-inline-share-buttons {
        height: 40px;
        max-width: 300px;
    }
    .st-inline-share-buttons .st-label {
        font-size: 18px;
    }
    .main_btn_block {
        margin-bottom: 50px;
    }
    .main_button.main_btn--share {
        padding: 5px 10px;
        max-width: 300px;
    }
} */
@media only screen and (max-width: 767px) {
    .navbar .menu ul {
        width: 380px;
    }
    .menu ul li a {
        font-size: 12px;
    }
    .navbar .logo a img {
        width: 60px;
    }
    .text h1 {
        font-size: 48px;
    }
    .text h2 {
        font-size: 30px;
    }
    .text h3 {
        font-size: 20px;
    }
    .text h4 {
        font-size: 15px;
    }
    .main-block .video video {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .background-noise {
        height: 792px;
    }
}
@media only screen and (max-width: 576px) {
    .menu {
        display: none;
    }
    .text h1 {
        font-size: 40px;
    }
    .text h2 {
        font-size: 25px;
        padding-bottom: 30px;
    }
    .text h3 {
        font-size: 15px;
    }
    .main-block .video video {
        /* margin-top: 0; */
        margin-bottom: 0;
    }
    .background-noise {
        height: 690px;
    }

    .main_creative {
        max-width: unset;
        width: 100%;
    }

    .main_copy_text {
        font-size: 14px;
        bottom: -40px;
    }
}
@media only screen and (max-width: 400px) {
    .text h1 {
        font-size: 35px;
    }
    .text h2 {
        font-size: 20px;
    }
    .main-block .video video {
        /* margin-top: 0; */
        margin-bottom: 0;
    }
    .main-block .video {
        height: 80%;
    }
    .background-noise {
        height: 660px;
    }
}
@media only screen and (max-width: 350px) {
    .text h1 {
        font-size: 33px;
    }
    .text h2 {
        font-size: 18px;
    }
    
}