.black {
    color: #000 !important;
}
.organizers_block_wrapper {
    position: relative;
    display: block;
    color: #dbdbdb;
    font-size: 16px;
    font-weight: 300;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: .7s;
    height: 500px;
    margin: 0 auto 40px;
    min-width: 250px;
    max-width: 310px;
    font-family: 'SpaceMono',Arial,sans-serif;
}

a.organizers_block_wrapper:hover {
    color: #dbdbdb;
}

.organizers_block {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    margin-bottom: 10px;
}

.organizers_block--Ed {
    background-image: url('../../img/Ed_chb.png');
}

.organizers_block--Ed:hover {
    background-image: url('../../img/Ed Nedin.png');
}

.organizers_block--Anna {
    background-image: url('../../img/Anna_chb.png');
}

.organizers_block--Anna:hover {
    background-image: url('../../img/Anna.png');
}

.organizers_block--Pavel {
    background-image: url('../../img/Pavel_chb.png');
}

.organizers_block--Pavel:hover {
    background-image: url('../../img/Pavel.png');
}

.organizers_block--Nik {
    background-image: url('../../img/Nik_chb.png');
}

.organizers_block--Nik:hover {
    background-image: url('../../img/Nik.png');
}

.organizers_name {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
}

.organizers_link,
.link {
    display: block;
    width: 20px;
    height: 20px;
    background-size: contain;
    margin-right: 5px;
    background-repeat: no-repeat;
}

.organizers_link:last-child {
    margin-right: 0;
}

.organizers_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.organizers_position {
    padding-right: 17px;
}

.organizers_link--facebook {
    background-image: url('../../img/Facebook_white.svg');
}

.organizers_link--ln {
    background-image: url('../../img/ln_white.svg');
}

.organizers_link--mail {
    background-image: url('../../img/mail_white.svg');
}

.organizers_links {
    display: flex;
}

@media screen and (max-width: 1400px) {
    .organizers_block_wrapper {
        min-width: 250px;
        max-width: 280px;
    }
}

@media screen and (max-width: 1200px) {
    .organizers_block_wrapper {
        min-width: 200px;
        max-width: 220px;
        height: 300px;
    }

    .organizers_position {
        font-size: 14px;
    }

    .organizers_name {
        font-size: 18px;
    }
}

@media screen and (max-width: 920px) {
    .organizers_block_wrapper {
        min-width: 200px;
        max-width: 220px;
        height: 300px;
        margin-bottom: 20px;
    }

    .organizers_name {
        font-size: 18px;
    }
    
    .organizers_position {
        font-size: 14px;
    }
}

@media screen and (max-width: 460px) {
    .organizers_block_wrapper {
        min-width: 130px;
        max-width: 220px;
        height: 230px;
    }

    .organizers_position {
        font-size: 9px;
        max-width: 100px;
    }

    .organizers_name {
        font-size: 12px;
    }

    .organizers_col {
        padding: 5px;
    }
}

@media screen and (max-width: 380px) {
    .organizers_block_wrapper {
        min-width: 110px;
        max-width: 220px;
        height: 200px;
    }
}