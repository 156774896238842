.openMenu {
    position: fixed;
    width: 100%;
    background-color: #000;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
}
.openMenu ul {
    margin-left: 0;
}
.openMenu ul li {
    list-style-type: none;
    text-align: center;
    font-size: 20px;
    padding-top: 20px;
    margin: 0;
}
.openMenu ul li a {
    text-decoration: none;
    color: #61dbfb;
}
.openMenu img {
    width: 25px;
    margin-top: 25px;
}
