.iframe_enterprise html {
    overflow: hidden;
}

@media screen and (max-width: 1200px) {
    .iframe_enterprise {
        height: 445px!important;
    }
}

@media screen and (max-width: 1019px) {
    .iframe_enterprise {
        height: 485px!important;
    }
}

@media screen and (max-width: 883px) {
    .iframe_enterprise {
        height: 460px!important;
    }
}

@media screen and (max-width: 520px) {
    .iframe_enterprise {
        height: 500px!important;
    }
}

@media screen and (max-width: 464px) {
    .iframe_enterprise {
        height: 510px!important;
    }
}