.partners {
    background-color: #000;
    padding: 50px 0;
}
.partners .partners-photos .partners-photos-line{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.partners .partners-photos .partners-photos-line a {
    max-width: calc(90% / 5);
    padding: 0 20px;
    display: block;
    width: 100%;
}
.partners .partners-photos .partners-photos-line img {
    margin-bottom: 20px;
    display: block;
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
    width: 100%;
}
.partners .partners-photos .partners-photos-line img:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}
@media only screen and (max-width: 640px) {
    .partners .partners-photos .partners-photos-line a {
        max-width: calc(90% / 4);
        padding: 0 10px;
    }
}

@media only screen and (max-width: 460px) {
    .partners .partners-photos .partners-photos-line a {
        max-width: calc(90% / 2);
    }
}