.countdown-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #61dbfb;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10000;
}
    
.countdown_text_block {
    display: flex;
}
    
.countdown-content-block {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
}
    
.countdown {    
    color: #000;
    display: flex;
    align-items: center;
    font-weight: 100;
    text-align: center;
    font-size: 30px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
}
    
.countdown-descr {
    font-size: 26px;
    font-weight: bold;
    margin-right: 15px;
    text-align: left;
    max-width: 190px;
    margin-bottom: 0;
}
    
.countdown-descr__small {
    max-width: 130px;
    color: #000;
}
    
.timer_block {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
    
    .countdown_btn {
    color: white;
    font-weight: bold;
    text-decoration: none;
    padding: 10px 20px;
    background-color: black;
    max-width: 250px;
    font-size: 28px;
    transition: .2s;
    line-height: 1.3;
    }
    
    .countdown_btn:hover {
    color: black;
    background-color: white;
    text-decoration: none;
    }
    
    .close_countdown {
    width: 40px;
    height: 40px;
    cursor: pointer;
    }
     
    .countdown-number {
      padding: 10px 20px;
      display: inline-block;
    }
    
    .countdown-number {
    position: relative;
    }
    
    .countdown-number::after {
    content: ':';
    position: absolute;
    top: 30%;
    right: -10%;
    transform: translateY(-50%);
    }
     
    .countdown-number:first-child::after {
        content: '';
    }
     
    .countdown-number:last-child::after {
        content: '';
    }

    .countdown-time {
      padding-bottom: 10px;
      display: inline-block;
    font-weight: bold;
    }
     
    .countdown-text {
      display: block;
      padding-top: 5px;
      font-size: 16px;
    }
    
    @media screen and (max-width: 1200px) {
    .countdown_btn {
    font-size: 20px;
    }
    
    .countdown-descr {
    font-size: 24px;
    }
    }
    
    @media screen and (max-width: 980px) {
    .countdown_btn {
    font-size: 18px;
    }
    
    .countdown-descr {
    max-width: 160px;
    }
    
    .countdown-descr__small {
    max-width: 120px;
    }
    
    .countdown-number {
      padding: 10px;
    }
    }
    
    @media screen and (max-width: 900px) {
    .countdown-content-block {
    flex-direction: column;
    }
    
    .countdown-descr {
    font-size: 16px;
    }
    
    .countdown_text_block {
    padding-bottom: 5px;
    }
    
    .countdown-descr {
    max-width: unset!important;
    }
    
    .countdown-number__dots::after {
    right: -70%;
    }
    
    .countdown-wrap {
    padding-bottom: 0;
    }
    
    .countdown_btn {
        max-width: unset;
        width: 140%;
    margin-left: 40px;
    }
    
    .close_countdown {
    margin-bottom: 50px;
    }

    .countdown-descr {
        margin-right: 9px;
        text-align: center;
    }
    }
    
    @media screen and (max-width: 440px) {
    
    .countdown-descr {
    font-size: 14px;
    }
    
    .countdown {
    font-size: 22px;
    }
    
    .countdown-text {
    font-size: 14px;
    }
    
    }
    
    @media screen and (max-width: 400px) {
    
    .countdown-descr {
    font-size: 11px;
    }
    
    }