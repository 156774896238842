.speakersFrom {
    background-color: #000;
    padding: 50px 0;
}
.speakersFrom .speakersFrom-photos .speakersFrom-photos-line{
    display: flex;
    justify-content: center;
}
.speakersfrom-logo-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    max-height: fit-content;
}

.speakersfrom-logo-block img {
    display: block;
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
    max-width: calc(90% / 5);
    padding: 0 20px;
    object-fit: contain;
}
.speakersfrom-logo-block img:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}

@media only screen and (max-width: 640px) {
    .speakersfrom-logo-block img {
        max-width: calc(90% / 4);
    }
}

@media only screen and (max-width: 460px) {
    .speakersfrom-logo-block img {
        max-width: calc(90% / 2);
        padding: 0 10px;
        max-height: 113px;
    }
}

@media only screen and (max-width: 420px) {
    .speakersfrom-logo-block img {
        max-height: 100px;
    }
}

@media only screen and (max-width: 400px) {
    .speakersfrom-logo-block img {
        max-height: 95px;
    }
}

@media only screen and (max-width: 380px) {
    .speakersfrom-logo-block img {
        max-height: 89px;
    }
}

@media only screen and (max-width: 386px) {
    .speakersfrom-logo-block img {
        max-height: 83px;
    }
}