.fourKeys {
    background-color: #000;
    font-family: 'SpaceMono',Arial,sans-serif;
}
.fourKeys_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fourKeys_item {
    margin: 0 10% 100px;
}
.fourKeys_item:nth-child(2n),
.fourKeys_item:nth-child(4){flex-direction: row-reverse;}

.fourKeys_title {
    color: #0057b8;
}

.fourKeys_item-info-title{
    font-weight: 400;
    font-size: 52px;
    line-height: 1.2;
    color: #ffd700;
    margin:0 0 10px;
}
.fourKeys_item-info-text {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.2;
    color: #FFFFFF;
    margin:0;
}

.fourKeys_item:nth-child(2n) img{
    margin-left: auto;
    margin-right: unset;
}

.fourKeys_item:nth-child(2n+1){
    text-align: right;
}

.fourKeys_item:nth-child(2n){
    text-align: left;
}

.fourKeys_item_image {
    max-height: 223px;
    height: 100%;
    object-fit: contain;
    margin-right: auto;
}

.fourKeys_item-half {
    width: 47%;
}

.fourKeys_item-img_block img {
    display: block;
}

@media screen and (max-width: 1600px) {
    .fourKeys_item-info-title {
        font-size: 40px;
    }

    .fourKeys_item-info-text {
        font-size: 24px;
    }

    .fourKeys_item {
        margin: 0 0 100px;
    }
}

@media screen and (max-width: 1400px) {
    .fourKeys_item-info-title {
        font-size: 38px;
    }
}

@media screen and (max-width: 1200px) {
    .fourKeys_item_image {
        max-height: 150px;
    }

    .fourKeys_item-info-title {
        font-size: 32px;
    }

    .fourKeys_item-info-text {
        font-size: 24px;
    }
}

@media screen and (max-width: 900px) {
    .fourKeys_item {
        margin: 0 0 50px;
    }

    .fourKeys_item-info-title {
        font-size: 26px;
    }

    .fourKeys_item-info-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 640px) {
    .fourKeys_item {
        flex-direction: column;
    }

    .fourKeys_item-half {
        width: 100%;
    }

    .fourKeys_item-info-title {
        font-size: 30px;
    }

    .fourKeys_item-img_block {
        order: 1;
    }

    .fourKeys_item_image {
        margin: 0 auto;
        margin-top: 20px;
    }

    .fourKeys_item:nth-child(2n) img {
        margin-right: auto;
        margin-left: auto;
    }

    .fourKeys_item-info-title {
        text-align: center;
    }

    .fourKeys_item-info-text {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .fourKeys_item-info {
        margin-bottom: 0;
        order: 1;
    }

    .fourKeys_item:nth-child(2n),
    .fourKeys_item:nth-child(4) {
        flex-direction: column;
    }

    .fourKeys .container {
        margin: 100px auto 100px;
    }
}