.Workshops {
    background-color: black;
}

.workshopmedia {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
    .workshopmedia {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .workshops-title h2 {
        margin-bottom: 0;
    }
}
