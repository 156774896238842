.audience_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    font-family: "SpaceMono", Arial, sans-serif;
    background: #000;
    padding: 100px 0;
}

.audience_wrap span {
    -webkit-text-stroke: 1px #61dbfb;
    color: transparent;
    font-size: 50px;
    line-height: 1.55;
    margin-right: 20px;
}

.audience_wrap span.green {
    color: #61dbfb;
    -webkit-text-stroke: 1px rgba(219,219,219,0.5);
    font-weight: 700;
}

@media only screen and (max-width: 1200px) {
    .audience_wrap span {
        font-size: 40px;
    }
}
@media only screen and (max-width: 976px) {
    .audience_wrap span {
        font-size: 30px;
    }

    .audience_wrap {
        padding: 50px 0;
    }
}
@media only screen and (max-width: 450px) {
    .audience_wrap span {
        font-size: 25px;
    }
}
