.speaker-main {
    position: relative;
    width: 47%;
    margin: auto 0;
}
.speaker {
    background-color: #000;
    width: 100%;
    border: 2px solid rgb(97, 219, 251);
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    z-index: 200;
    padding: 20px;
    min-height: 250px;
}
.speaker div.speaker-flex {
    display: flex;
    justify-content: space-around;
}
.speaker div.speaker-flex-2 {
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.speaker div.speaker-flex:first-child {
    justify-content: initial;
}
.speaker-photo {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 25px;
    justify-content: space-between;
}
.speaker-name {
    color: rgb(97, 219, 251);
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 600;
}
.speaker-pos {
    color: #dbdbdb;
    font-size: 12px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    padding-top: 10px;
}
.speaker-social {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
}
.speaker-photo-img {
    max-height: 134px;
    height: 100%;
}
.speaker-social a img {
    width: 20px;
    margin-right: 10px;
}
.speaker-abstract {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
    width: 280px;
}
.speaker-abstract-title {
    color: #dbdbdb;
    font-size: 18px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
}
.speaker-button {
    color: #000000;
    font-size: 16px;
    font-family: "SpaceMono", Arial, sans-serif;
    font-weight: 600;
    background-color: #61dbfb;
    min-width: 180px;
    max-width: 225px;
    width: 100%;
    height: 35px;
    border: none;
    margin-top: 10px;
    border: 1px solid #61dbfb;
    transition: 1s;
    text-transform: uppercase;
}
.speaker-button:hover {
    color: #61dbfb;
    background-color: #000000;
    border: 1px solid #61dbfb;
}
.speaker-logo img {
    width: 200px;
}
.speaker-info {
    min-width: 180px;
    max-width: 270px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.speaker-logo-img {
    position: absolute;
    bottom: 0;
    max-height: 60px;
    object-fit: contain;
    left: 0;
}

.speakers-block.hide {
    display: none;
    flex-wrap: wrap;
}

.speaker_plashka {
    color: #dbdbdb;
    font-size: 12px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1;
    font-weight: 400;
    padding: 5px;
    border: 1px solid #61dbfb;
    position: absolute;
    top: -1px;
    right: -1px;
}

.speaker_plashka.speaker_senior {
    background-color: #61dbfb;
    color: #000;
}
@media screen and (max-width: 1600px) {
    .speaker-abstract {
        max-width: 260px;
    }
    .speaker-info {
        max-width: 200px;
    }
}

@media screen and (max-width: 1400px) {
    .speaker-abstract-title {
        font-size: 14px;
    }

    .speaker-photo-img {
        max-height: 110px;
    }
    .speaker-abstract {
        max-width: 210px;
    }
}

@media screen and (max-width: 1200px) {
    .speaker-social a img {
        width: 15px;
        margin-right: 7px;
    }
    .speaker {
        padding: 20px 20px 20px 10px;
    }
    .speaker-photo {
        margin-right: 10px;
    }
    .speaker-photo-img {
        max-height: 95px;
    }
    .speaker-info {
        min-width: 100px;
    }

    .speaker-button {
        min-width: 100px;
        max-width: 145px;
        font-size: 14px;
    }
    .speaker-button {
        height: 30px;
        max-width: 135px;
    }
    .speaker-logo-img {
        max-height: 40px;
    }
    .speaker-abstract {
        width: 160px;
    }
    .speaker-abstract-title {
        width: 95%;
    }
    .speaker-logo-img {
        max-width: 90px;
    }
    .speaker-name {
        font-size: 19px;
    }
    .speaker-pos {
        font-size: 11px;
        padding-top: 0;
    }
}

@media only screen and (max-width: 991px) {
    .speaker-main {
        width: 100%;
    }

    .speaker {
        max-width: 670px;
        margin: 0 auto 20px;
    }

    .speaker-button {
        font-size: 14px;
    }
    .speaker-photo-img {
        max-height: 100px;
    }
    .speaker-abstract-title {
        font-size: 18px;
    }

    .speaker-photo {
        margin-right: 20px;
        /* margin-top: 20px; */
    }

    .speaker div.speaker-flex-2 {
        width: 22%;
    }

    .speaker-info {
        min-width: 90px;
        max-width: 110px;
    }
}
@media only screen and (max-width: 600px) {
    .speaker-abstract {
        max-width: 180px;
    }
    .speaker {
        min-height: 220px;
        padding-left: 5px;
    }

    .speaker-abstract-title {
        font-size: 14px;
    }

    .speaker-name {
        font-size: 16px;
    }

    .speaker-info {
        max-width: 110px;
    }

    .speaker-name.speaker-name-2 {
        font-size: 14px;
    }
}
@media only screen and (max-width: 430px) {
    .speaker-photo {
        margin-right: 10px;
    }

    .speaker div.speaker-flex-2 {
        width: 30%;
    }

    .speaker-photo-img {
        max-height: 70px;
    }

    .speaker-info {
        min-width: 60px;
    }

    .speaker-button {
        min-width: 80px;
        max-width: 115px;
    }

    .speaker-logo-img {
        max-height: 27px;
    }

    .speaker-button {
        font-size: 12px;
        min-width: 120px;
        max-width: 225px;
        height: 30px;
    }
}
@media only screen and (max-width: 380px) {
    .speaker-abstract-title {
        font-size: 12px;
    }
    .speaker-name {
        font-size: 12px;
        margin-top: 5px;
    }
    .speaker-abstract {
        max-width: 110px;
    }
    .speaker-button {
        font-size: 10px;
        width: 95px;
        min-width: 50px;
    }
    .speaker-name-2 {
        font-size: 11px;
    }
    .speaker-logo-img {
        max-width: 70px;
    }
    .speaker-name-2 {
        font-size: 11px;
    }
}
