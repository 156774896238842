.packages {
    background-color: #000;
    padding: 50px 0;
}
.packages .packages-block {
    color: #000;
    display: flex;
    justify-content: space-between;
    font-family: "SpaceMono";
    position: relative;
    padding-top: 50px;
    overflow: hidden;
}
.packages .packages-block .packages-block-1,
.packages .packages-block .packages-block-2 {
    width: 49%;
    padding-top: 20px;
}
.packages-block:first-child {
    padding-top: 0!important;
}
.packages .packages-block .packages-block-2 {
    background-color: #61dbfb;
}
.packages .packages-block .packages-block-1 {
    background-color: #dbdbdb;
}
.packages .packages-block .packages-block-title {
    height: 120px;
}
.packages .packages-block .packages-block-title h2 {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}
.packages .packages-block .packages-block-title p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
}
.packages .packages-text {
    /* display: flex; */
    width: 230%;
    height: 70px;
    position: absolute;
    overflow: hidden;
    animation: packages-text 12s linear infinite;
    top: 160px;
}

.packages .packages-text span {
    color: #000000;
    font-size: 62px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin-right: 40px;
}
@keyframes packages-text {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
.packages .packages-block .packages-block-info {
    margin-top: 100px;
    height: 320px;
    padding-left: 50px;
}
.packages .packages-block .packages-block-info div {
    display: flex;
    align-items: baseline;
}
.packages .packages-block .packages-block-info img {
    margin-right: 20px;
}
.packages .packages-block .packages-block-info p {
    font-size: 25px;
    font-weight: 600;
}
.packages .packages-block hr {
    width: 83%;
    height: 2px;
    color: #000;
    margin: 0 auto;
    opacity: 1;
}
.packages .packages-block .packages-block-price {
    padding-top: 20px;
    padding-bottom: 30px;
}
.packages .packages-block .packages-block-price a{
    text-decoration: none;
}
.packages .packages-block .packages-block-price h3 {
    font-size: 45px;
    font-weight: 600;
    text-align: center;
}
.packages .packages-block .packages-block-price button {
    color: #000000;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid transparent;
    background-color: #61dbfb;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border-color 0.4s ease-in-out;
    width: 90%;
    height: 60px;
    display: block;
    margin: 40px auto 0;
}
.packages .packages-block .packages-block-price button:hover {
    background-color: #000000;
    color: #dbdbdb;
}
.packages .packages-block .packages-block-2 .packages-block-price button {
    background-color: #000;
    color: #dbdbdb;
}
.packages .packages-block .packages-block-2 .packages-block-price button:hover {
    background-color: #dbdbdb;
    color: #000;
}
@media only screen and (max-width: 1200px) {
    .packages .packages-text {
        width: 270%;
    }
    .packages .packages-text span {
        font-size: 40px;
    }
    .packages-block-2, .packages-block-1 {
        padding: 10px;
    }
    .packages .packages-block .packages-block-info {
        margin-top: 60px;
    }
    .packages .packages-block .packages-block-info p {
        font-size: 18px;
    }
    .packages .packages-block .packages-block-price {
        height: 210px;
    }
    .packages .packages-block .packages-block-price h3 {
        font-size: 40px;
    }
    .packages .packages-block .packages-block-price button {
        font-size: 25px;
        height: 60px;
    }
    .packages .packages-block .packages-block-title {
        height: 180px;
    }
    .packages .packages-text {
        height: 50px;
        top: 180px;
    }
}
@media only screen and (max-width: 991px) {
    .packages .packages-block {
        flex-direction: column;
        align-items: center;
    }
    .packages .packages-text {
        display: none;
    }
    .packages .packages-block .packages-block-1,
    .packages .packages-block .packages-block-2 {
        width: 70%;
        padding-top: 10px;
    }
    .packages .packages-block .packages-block-2 {
        margin-top: 30px;
    }
    .packages .packages-block .packages-block-title {
        height: 120px;
    }

    .packages .packages-block .packages-block-title p {
        padding-top: 10px;
    }

    .packages .packages-block .packages-block-info.packages-block-info-1 {
        margin-top: 30px;
        height: 150px;
    }

    .packages .packages-block .packages-block-price {
        padding-bottom: 15px;
        height: auto!important;
    }

    .packages .packages-block .packages-block-title {
        height: auto!important;
    }
}
@media only screen and (max-width: 730px) {
    .packages .packages-block .packages-block-1,
    .packages .packages-block .packages-block-2 {
        width: 90%;
    }
}
@media only screen and (max-width: 500px) {
    .packages .packages-block .packages-block-title h2 {
        font-size: 35px;
    }
    .packages .packages-block .packages-block-title p {
        font-size: 14px;
    }
    .packages .packages-block .packages-block-title {
        height: 90px;
    }
    .packages .packages-block .packages-block-info p {
        font-size: 15px;
    }
    .packages .packages-block .packages-block-info img {
        width: 12px;
    }
    .packages .packages-block .packages-block-info {
        height: 100%;
        max-height: 260px;
    }
    .packages .packages-block .packages-block-1 .packages-block-info {
        height: 160px;
    }
    .packages .packages-block .packages-block-price h3 {
        font-size: 30px;
    }
    .packages .packages-block .packages-block-price button {
        font-size: 20px;
        height: 50px;
        margin-top: 25px;
    }
    .packages .packages-block .packages-block-price {
        height: 160px;
    }
    .packages .packages-block .packages-block-1, .packages .packages-block .packages-block-2 {
        width: 100%;
    }
}
@media screen and (max-width: 440px) {
    .packages .packages-block .packages-block-info {
        padding-left: 23px;
    }
}

@media screen and (max-width: 360px) {
    .packages .packages-block .packages-block-info {
        padding-left: 15px;
    }
}