.agenda_item {
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 15px 25px;
    margin-bottom: 30px;
}

.agenda_item_first {
    display: flex;
    width: 45%;
    align-self: center;
    align-items: center;
}

.agenda_item_second {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.agenda_speaker{
    display: flex;
}

.agenda_info{
    display: flex;
    align-items: center;
    justify-content: center;
}
.agenda_speaker_info{
    width:210px;
}

.agenda_photo {
    max-width: 87px;
    object-fit: contain;
    margin: 0 15px;
}

.agenda_name {
    font-size: 23px;
    color: rgb(97, 219, 251);
}

.agenda_position {
    font-size: 14px;
    color: rgb(255, 255, 255);
}

.agenda_logo {
    max-height: 49px;
    object-fit: contain;
}

.agenda_time{
    font-size: 16px;
    color: rgb(255, 255, 255);
}
.agenda_title {
    font-size: 16px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
    margin: 0 15px;
    max-width: 630px;
    width: 100%;
}
.agenda_social{
    display: flex;
    flex-direction: column;
    max-height: 110px;
}
.agenda_social a{
    display: flex;
    /* flex-direction: column; */
    width:16px;
    height: 16px;
    margin: 0 0 10px 15px;
}
.agenda_social img{
    width:16px;
    height: 16px;
}

.agenda_link_wrap{
    display: flex;
    flex: 1 0 50%;
    justify-content: center;
}

.agenda_link {
    background-color: transparent;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: background 200ms ease 0s;
    border: solid 1px #fff;
    padding: 10px 20px;
    min-width: 183px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
}

.agenda_link:hover {
    background: rgb(97, 219, 251);
    border: solid 1px rgb(97, 219, 251);
    color: black;
}

@media screen and (max-width: 1200px) {
    .agenda_title {
        font-size: 16px;
    }

    .agenda_speaker_info {
        width: 130px;
    }
}

@media screen and (max-width: 1000px) {
    .agenda_item_first {
        flex-direction: column;
        width: 50%;
    }

    .agenda_item_second {
        width: 50%;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        align-items: center;
    }

    .agenda_item_second p{
        text-align: center;
    }

    .agenda_social{
        justify-content: space-between;
        margin-top: 10px;
    }

    .agenda_social a{
        margin: 0 0 10px 0;
    }
    .agenda_link {
        margin-top: 10px;
        font-size: 16px;
    }

    .agenda_link_wrap {
        flex: unset;
    }
}

@media screen and (max-width: 640px) {
    .agenda_speaker_info{
        width:90px;
    }
    
    .agenda_title {
        font-size: 16px;
    }

    .agenda_photo {
        max-width: 50px;
    }

    .agenda_name {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .agenda_position {
        font-size: 11px;
    }

    .agenda_item {
        padding: 15px;
    }

    .agenda_link_wrap{
        flex: 0 0 0;
    }

    .agenda_link {
        min-width: 143px;
        padding: 10px;
    }

    .agenda_title {
        margin: 0;
    }

    .agenda_photo {
        margin: 0 3px;
    }
}
