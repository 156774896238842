@media screen and (max-width: 1600px) {
    .iframe_askcompany {
        height: 347px!important;   
    }
}

@media screen and (max-width: 1400px) {
    .iframe_askcompany {
        height: 337px!important;
    }
}

@media screen and (max-width: 1239px) {
    .iframe_askcompany {
        height: 369px!important;
    }
}

@media screen and (max-width: 1200px) {
    .iframe_askcompany {
        height: 481px!important;
    }
}

@media screen and (max-width: 1020px) {
    .iframe_askcompany {
        height: 473px!important;
    }
}

@media screen and (max-width: 1000px) {
    .iframe_askcompany {
        height: 430px!important;
    }
}

@media screen and (max-width: 980px) {
    .iframe_askcompany {
        height: 425px!important;
    }
}

@media screen and (max-width: 710px) {
    .iframe_askcompany {
        height: 444px!important;
    }
}

@media screen and (max-width: 664px) {
    .iframe_askcompany {
        height: 464px!important;
    }
}

@media screen and (max-width: 655px) {
    .iframe_askcompany {
        height: 485px!important;
    }
}

@media screen and (max-width: 517px) {
    .iframe_askcompany {
        height: 509px!important;
    }
}

@media screen and (max-width: 500px) {
    .iframe_askcompany {
        height: 475px!important;
    }
}