.modal-header {
    border-bottom: 0;
    padding: 0;
}
.modal-content {
    background-color: #e4e4e4;
    align-items: flex-start;
    margin: 0;
    padding: 3% 5% 3% 5%;
}
.modal-header h1 {
    font-size: 32px;
    color: black;
    padding: 2% 0;
    font-family: 'Righteous',Arial,sans-serif;
}
.modal-dialog {
    width: 650px;
    position: absolute;
    left: calc(50% - 250px);
    top: 10%;
}
.modal-body {
    padding: 0;
    margin: 0;
    width: 90%;
}
.modal-content p {
    font-size: 16px;
    font-family: 'Righteous',Arial,sans-serif;
}
@media only screen and (max-width: 600px) {
    .modal-header h1 {
        font-size: 20px;
    }
    .modal-content p {
        font-size: 12px;
    }
    .modal-dialog {
        width: 300px;
        position: absolute;
        left: calc(50% - 150px);
    }
}