.agenda {
    background-color: black;
    font-family: "SpaceMono";
}
.agenda p {
    margin-bottom: 0;
}

.agenda_title_block {
    margin-bottom: 40px;
}
.agenda a.showbutton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    min-width: 250px;
    text-decoration: none;
}
.agenda a.showbutton:hover{
    border:solid 1px #fff;
}
@media screen and (max-width: 1000px) {
    .agenda a.showbutton{
        font-size: 20px;
    }
}