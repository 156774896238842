.reviews {
    background-color: black;
    font-size: 14px;
    font-family: 'SpaceMono',Arial,sans-serif;
}

.review_wrap {
    width: 50%!important;
    flex: none;
}

@media screen and (max-width: 800px) {
    .review_wrap {
        width: 100%!important;
    }
}

.review {
    padding: 0 50px;
}

.reviews_title {
    margin-bottom: 50px;
}

.review {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.review_info {
    position: relative;
}

.review_icon {
    position: absolute;
    top: 22%;
    left: -5%;
    max-width: 56px;
}

.review_photo {
    object-fit: contain;
    min-width: 112px;
    margin-right: 30px;
    border-radius: 100%;
    box-shadow: rgba(69, 209, 253, 0.48) -10px 10px 54px 0px;
}

.review_name {
    color: rgb(69, 209, 253);
    font-size: 18px;
    margin-bottom: 10px;
}

.review_position {
    font-size: 12px;
    color: rgb(134, 134, 139);
    margin-bottom: 8px;
}

.review_text {
    color: rgb(251, 251, 251);
}

@media screen and (max-width: 1280px) {
    .review {
        padding: 0 10px;
    }
}

@media screen and (max-width: 640px) {
    .review {
        padding: 0;
    }

    .review_name {
        color: rgb(69, 209, 253);
        font-size: 16px;
        margin-bottom: 10px;
    }
    
    .reviews {
        font-size: 12px;
    }

    .review_photo {
        min-width: 70px;
        margin-right: 20px;
        max-height: 70px;
    }
}