.menu ul {
    display: flex;
    justify-content: space-between;
    width: 550px;
    list-style: none;
}
.menu ul li a {
    text-decoration: none;
    color: #61dbfb;
    font-size: 16px;
    font-family: "Righteous";
    text-transform: uppercase;
}
.menu ul li:hover a {
    color: #fff;
}
.hamburgerMenu {
    cursor: pointer;
}
.mobile-menu .modal-dialog {
    background-color: black !important;
}
.modal-menu-mobile {
    background-color: red;
}
.hamburgerMenu {
    cursor: pointer;
}
.mobile-menu {
    display: none;
}
@media only screen and (max-width: 767px) {
    .navbar .menu ul {
        width: 380px;
    }
    .menu ul li a {
        font-size: 12px;
    }
}
@media only screen and (max-width: 576px) {
    .menu {
        display: none;
    }
    .mobile-menu {
        display: block;
        position: relative;
        z-index: 200;
    }
}
