.speakers {
    background-color: #fff;
    padding: 40px 0;
}
.hide {
    display: none;
}
.showbutton {
    color: #000000;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid transparent;
    background-color: #61dbfb;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border-color 0.4s ease-in-out;
    width: 90%;
    height: 80px;
    display: block;
    margin: 40px auto 0;
}
.hidebutton {
    color: #000000;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid transparent;
    background-color: #61dbfb;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border-color 0.4s ease-in-out;
    width: 90%;
    height: 80px;
    display: none;
    margin: 40px auto 0;
}
.showbutton:hover {
    background-color: #000000;
    color: #dbdbdb;
}

.speakers-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.speaker-info-wrap {
    display: flex;
    flex-direction: column;
}

.speaker-logo-img-2 {
    position: inherit!important;
    max-height: 40px!important;
}

.speaker-photo-img-2 {
    max-height: 73px!important;
}

.speaker-social-2 {
    margin-top: 10px!important;
    margin-bottom: 10px!important;
}

.speaker-2 {
    max-height: 260px!important;
    min-height: 250px!important;    
}

.speaker-abstract-title-2 {
    margin-top: 82px;
}

.speaker-pos-2 {
    margin-bottom: 0;
}

.speaker-info-wrap-2 {
    /* margin: auto; */
    margin-left: 0;
}

.speaker-name-2 {
    line-height: 1.2!important;
}

@media screen and (max-width: 1400px) {
    .speaker-abstract-title-2 {
        margin-top: 90px;
    }

    .speaker-name {
        font-size: 12px;
    }

    .speaker-logo-img-2 {
        max-height: 30px!important;
    }

    .speaker-pos {
        font-size: 10px;
    }
}

@media screen and (max-width: 1285px) and (min-width: 1200px) {
    .speaker div.speaker-flex-2 {
        width: 20%;
    }

    .speaker-abstract {
        max-width: 220px;
    }

    .speaker-info {
        min-width: 130px!important;
        max-width: 130px!important;
    }
}

@media screen and (max-width: 1100px) {
    .speaker-name-2 {
        font-size: 12px!important;
    }
}

@media screen and (max-width: 800px) {
    .speaker-abstract-title-2 {
        margin-top: 80px;
    }

    .speaker-2 {
        max-height: unset!important;
        min-height: unset!important;
        padding: 20px!important;
    }

    .speaker-logo-img-2 {
        max-height: 32px!important;
    }

    .speaker-name-2 {
        font-size: 14px!important;
    }
}