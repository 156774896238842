.footer {
    border-top: 2px solid rgb(97, 219, 251);
    background-color: #000;
    padding: 70px 0;
}
.footer .logo a img {
    width: 110px;
}
.footer .logo {
    min-width: 130px;
}
.footer .social {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 110px;
} 
.footer .social a img {
    width: 20px;
} 
.footer .footer-text {
    min-width: 180px;
}
.footer .footer-text p {
    color: #fff;
    margin: 0;
    font-family: 'SpaceMono';
}
.footer .footer-links a {
    color: #fff;
    margin: 0;
    margin-bottom: 10px;
    font-family: 'SpaceMono';
    text-decoration: none;
    border-bottom: 1px solid rgb(97, 219, 251);
    display: inline-block;

}
.footer .footer-links {
    min-width: 160px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
}
.footer .footer-links-2 a {
    color: #fff;
    margin: 0;
    margin-bottom: 10px;
    font-family: 'SpaceMono';
    display: block;
}
.footer .footer-links-2 {
    min-width: 160px;
}
@media only screen and (max-width: 991px) {
    .footer .container, .container-sm {
        max-width: 740px;
    }
}
@media only screen and (max-width: 725px) {
    .footer .footer-links-2 {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 541px) {
    .footer .footer-links {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 420px) {
    .footer .footer-links {
        margin-top: 20px;
    }
    .footer .footer-text p, .footer a {
        font-size: 13px;
    }
    .footer .logo,.footer .footer-text, .footer .footer-links, .footer .footer-links-2 {
        min-width: 130px;
    }
}