.news_item {
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 15px 25px;
    margin-bottom: 30px;
}

.news_item_first {
    display: flex;
    width: 30%;
    align-self: center;
}

.news_item_second {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.news_photo {
    max-width: 87px;
    object-fit: contain;
    margin-right: 30px;
}

.news_name {
    font-size: 23px;
    color: rgb(97, 219, 251);
}

.news_position {
    font-size: 14px;
    color: rgb(255, 255, 255);
}

.news_logo {
    max-height: 49px;
    object-fit: contain;
}

.news_title {
    font-size: 30px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
    margin: 0 15px;
    max-width: 630px;
    width: 100%;
}

.news_link {
    background: rgb(97, 219, 251);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: background 200ms ease 0s;
    padding: 10px 20px;
    min-width: 183px;
    max-height: 42px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
}

.news_link:hover {
    background-color: white;
    color: black;
}

@media screen and (max-width: 1200px) {
    .news_title {
        font-size: 24px;
    }
}

@media screen and (max-width: 1000px) {
    .news_item_first {
        flex-direction: column;
        width: 50%;
    }

    .news_item_second {
        width: 50%;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 640px) {
    .news_title {
        font-size: 16px;
    }

    .news_photo {
        max-width: 67px;
    }

    .news_name {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .news_position {
        font-size: 11px;
    }

    .news_item {
        padding: 15px;
    }

    .news_link {
        min-width: 143px;
        padding: 10px;
    }

    .news_title {
        margin: 0;
    }
}
