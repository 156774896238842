.ourMission {
    background-color: #000;
    padding-bottom: 50px;
}
.ourMission .ourMission-title {
    position: relative;
    z-index: 100;
}
.ourMission .ourMission-title h2{
    padding-top: 60px;
}
.ourMission .ourMission-photo img{
    width: 100%;
    position: relative;
    z-index: 100;
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
}
.ourMission .ourMission-photo img:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
  }
.ourMission .ourMission-text{
    position: relative;
    z-index: 100;
}
.ourMission .ourMission-text p{
    color: #dbdbdb;
    font-size: 20px;
    font-family: 'SpaceMono',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 400;
}
@media only screen and (max-width: 1200px) {
    .ourMission .ourMission-text p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 991px) {
    .ourMission {
        padding-bottom: 50px;
    }
    .ourMission .row {
        flex-direction: column;
    }
    .ourMission .ourMission-text {
        padding-top: 30px;
    }
}
@media only screen and (max-width: 490px) {
    .ourMission {
        padding-bottom: 20px;
    }
    .ourMission .ourMission-text p{
        font-size: 16px;
    }
}
