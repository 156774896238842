.sponsors {
    background-color: #000;
}
.sponsors .sponsors-photos {
    /* padding-top: 30px; */
}
.sponsors .sponsors-photos h3{
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    font-family: 'SpaceMono';
    text-align: center;
}
.sponsors .sponsors-photos .sponsors-photos-line{
    display: flex;
    justify-content: center;
}
.sponsors .sponsors-photos .sponsors-photos-line div{
    display: flex;
    width: 45%;
    justify-content: center;
}
.sponsors-photos-img {
    margin-bottom: 20px;
    display: block;
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
    max-width: 300px;
    padding: 0 20px;
}

.sponsors-photos-img--big {
    max-width: 400px;
}

.sponsors-photos-img:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}
@media only screen and (max-width: 991px) {
    .sponsors .sponsors-photos .sponsors-photos-line{
        flex-direction: column;
    }
    .sponsors .sponsors-photos .sponsors-photos-line div{
        width: 100%;
    }
}
@media only screen and (max-width: 800px) {
    .sponsors-photos-img--big {
        max-width: 300px;
    }
}
@media only screen and (max-width: 600px) {
    .sponsors-photos-img {
        width: 175px;
        padding: 0 10px;
    }

    .sponsors-photos-img--big {
        max-width: 250px;
    }
}
@media only screen and (max-width: 450px) {
    .sponsors-photos-img {
        width: 150px;
    }

    .sponsors-photos-img--big {
        max-width: 170px;
    }
}