@font-face {
  font-family: 'Righteous';
  src: url('./fonts/Righteous-Regular.ttf');
}
@font-face {
  font-family: 'SpaceMono';
  src: url('./fonts/SpaceMono-Regular.ttf');
}
input:focus, input:active {
  outline: none;
}
button {
  cursor: pointer;
}
.title{
  color: #dbdbdb;
  font-size: 50px;
  font-family: 'SpaceMono',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.main_block {
  padding: 50px 0;
}
/* h2 {
  font-size: 50px;
  font-weight: 700;
} */

img {
  vertical-align: unset!important;
}

.title.title--black {
  color: #000000;
}

.container {
  max-width: 1620px!important;
  padding: 0 20px;
}

/* p:first-child {
  margin-bottom: 0;
} */

@media only screen and (max-width: 1620px) {
  .container {
    max-width: 100%!important;
    padding: 0 70px;
  }
}

@media only screen and (max-width: 1280px) {
  .title {
    font-size: 35px;
  }

  .main_block {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
  .main_block {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 600px) {
  .title {
      font-size: 32px;
  }
  .main_block {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 450px) {
  .title {
      font-size: 22px;
  }
}
