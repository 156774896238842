.apply {
    background-color: #000;
    font-family: "SpaceMono", Arial, sans-serif;
}

.apply.main_block {
    padding: 50px 0 100px;
}

.apply_item {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    margin: 0 auto;
}

.apply_img {
    width: 60px;
    height: 60px;
    margin: 0 auto 30px;
}

.apply_title {
    color: #dbdbdb;
    font-size: 25px;
    line-height: 1.55;
    font-weight: 700;
}

.apply_btn {
    color: black;
    width: 100%;
    transition: 0.7s;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.apply_btn:hover {
    color: #000;
}

.apply_btn:last-child {
    margin-right: 0;
}

.apply_btn--blue {
    background-color: #61dbfb;
}

.apply_btn--blue:hover {
    background-color: #dbdbdb;
}

.apply_btn--grey {
    background-color: #dbdbdb;
}

.apply_btn--grey:hover {
    background-color: #61dbfb;
}

.apply_btns {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 992px) {
    .apply_item {
        max-width: 260px;
    }

    .apply_btn {
        font-size: 18px;
    }
}

@media screen and (max-width: 640px) {
    .apply_item {
        min-width: 300px;
        max-width: 360px;
        margin-bottom: 40px;
    }

    .apply_btn {
        font-size: 18px;
        width: 90%;
    }
}
