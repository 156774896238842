.blocks {
    background-color: #000;
    padding-bottom: 100px;
}
.block {
    display: flex;
}
.block-item.block-item-mob {
    display: none;
}
.block-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 20px 0;
}
.block-item h3 {
    font-size: 32px;
    color: #fff;
    font-family: 'SpaceMono';
}
.block-item img {
    max-width: 350px;
}

@media screen and (max-width: 1600px) {
    .block-item h3 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 991px) {
    .block-item h3 {
        font-size: 25px;
    }
    .block-item img {
        max-width: 250px;
    }
    .block-item {
        padding: 30px 0;
        width: 100%;
    }

    .blocks {
        background-color: #000;
        padding-bottom: 40px;
    }
}
@media only screen and (max-width: 600px) {
    .block {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }
    .block:first-child {
        margin-top: 0!important;
    }
    .block-item {
        padding: 20px 0;
    }
    .block-item h3 {
        text-align: center;
    }

    .block-item.block-item-hide {
        display: none;
    }

    .block-item.block-item-mob {
        display: block;
    }
}
@media only screen and (max-width: 450px) {
    .block-item {
        width: 100%;
        padding-bottom: 0;
    }
    .block-item h3 {
        font-size: 16px;
    }
    .block-item img {
        max-width: 200px;
        margin-bottom: 30px;
    }
}