.team {
    background-color: #000;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.team_photo {
    width: calc(100% / 4);
    height: auto;
    padding: 7px;
    z-index: 100;
    transition: filter .7s ease-in-out;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
    object-fit: contain;
    display: block;
}

.team_photo:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}

@media screen and (max-width: 800px) {
    .team_photo {
        padding: 5px;
        width: calc(100% / 2);
        max-height: 300px;
    }
}

@media screen and (max-width: 700px) {
    .team_photo {
        max-height: 270px;
    }
}

@media screen and (max-width: 600px) {
    .team_photo {
        max-height: 230px;
    }
}

@media screen and (max-width: 500px) {
    .team_photo {
        max-height: 194px;
    }
}

@media screen and (max-width: 450px) {
    .team_photo {
        max-height: 155px;
    }
}

@media screen and (max-width: 400px) {
    .team_photo {
        max-height: 146px;
    }
}

@media screen and (max-width: 380px) {
    .team_photo {
        max-height: 125px;
    }
}