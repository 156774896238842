.whatsNew {
    background-color: #000;
    padding: 100px 0;
}
.whatsNew .whatsNew-title {
    position: relative;
    z-index: 100;
    padding: 80px 0 30px;
}
.whatsNew .whatsNew-title h2 {
    text-transform: uppercase;
    transition: 1s ease;
    position: absolute;
    top: 0;
}
.whatsNew .whatsNew-title h2:nth-child(2) {
    left: 53%;
}
.whatsNew .whatsNew-title h2:hover {
    font-size: 55px;
}
.cloud_react-logo {
    max-width: 80px;
    height: auto;
    animation: logo-rotate 10s infinite linear;
    position: absolute;
    top: 0;
    left: 42%;
}

.whatsNew-cloud p:hover {
    transform: scale(1.1);
}

@keyframes logo-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.whatsNew-cloud {
    position: relative;
    z-index: 100;
    height: 600px;
}
.whatsNew-cloud p {
    position: absolute;
    transition: 1s ease;
}
.whatsNew-cloud p.item-1 {
    color: #61dbfb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    left: 115px;
}
.whatsNew-cloud p.item-2 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    left: 65%;
    top: 25px;
}
.whatsNew-cloud p.item-3 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    left: 24%;
    top: 70px;
}
.whatsNew-cloud p.item-4 {
    color: #61dbfb;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    left: 58%;
    top: 55px;
}
.whatsNew-cloud p.item-5 {
    color: #61dbfb;
    font-size: 50px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    top: 100px;
    left: 84px;
}
.whatsNew-cloud p.item-6 {
    color: #dbdbdb;
    font-size: 60px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 89px;
    left: 64%;
}
.whatsNew-cloud p.item-7 {
    color: #dbdbdb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 170px;
    left: 23%;
}
.whatsNew-cloud p.item-8 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 195px;
    left: 61%;
}
.whatsNew-cloud p.item-9 {
    color: #61dbfb;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 158px;
    left: 77%;
}
.whatsNew-cloud p.item-10 {
    color: #dbdbdb;
    font-size: 60px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 220px;
    left: 90px;
}
.whatsNew-cloud p.item-11 {
    color: #dbdbdb;
    font-size: 25px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    opacity: 0.5;
    top: 250px;
    left: 80%;
}
.whatsNew-cloud p.item-12 {
    color: #61dbfb;
    font-size: 50px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 300px;
    left: 15%;
}
.whatsNew-cloud p.item-13 {
    color: #61dbfb;
    font-size: 80px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    top: 270px;
    left: 71%;
}
.whatsNew-cloud p.item-14 {
    color: #dbdbdb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    top: 350px;
    left: 5%;
}
.whatsNew-cloud p.item-15 {
    color: #dbdbdb;
    font-size: 60px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 355px;
    left: 41%;
}
.whatsNew-cloud p.item-16 {
    color: #61dbfb;
    font-size: 30px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 420px;
    left: 6%;
}
.whatsNew-cloud p.item-17 {
    color: #dbdbdb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 430px;
    left: 28%;
}
.whatsNew-cloud p.item-18 {
    color: #dbdbdb;
    font-size: 25px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    opacity: 0.5;
    top: 430px;
    left: 81%;
}
.whatsNew-cloud p.item-19 {
    color: #dbdbdb;
    font-size: 25px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    opacity: 0.5;
    top: 480px;
    left: 9%;
}
.whatsNew-cloud p.item-20 {
    color: #61dbfb;
    font-size: 50px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 480px;
    left: 29%;
}
.whatsNew-cloud p.item-21 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 500px;
    left: 59%;
}
.whatsNew-cloud p.item-22 {
    color: #dbdbdb;
    font-size: 20px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    top: 555px;
    left: 30%;
}
.whatsNew-cloud p.item-23 {
    color: #61dbfb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.35;
    font-weight: 400;
    top: 530px;
    left: 65%;
}
.whatsNew-cloud p.item-24 {
    color: #61dbfb;
    font-size: 40px;
    font-family: "SpaceMono", Arial, sans-serif;
    line-height: 1.25;
    font-weight: 400;
    opacity: 0.5;
    top: 600px;
    left: 63%;
}
@media only screen and (max-width: 1450px) {
    .whatsNew-cloud p.item-3 {
        left: 17%;
    }
    .whatsNew-cloud p.item-4 {
        left: 54%;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 50px;
        left: 59%;
    }
    .whatsNew-cloud p.item-7 {
        left: 13%;
    }
    .whatsNew-cloud p.item-8 {
        left: 58%;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 50px;
    }
    .whatsNew-cloud p.item-12 {
        left: 12%;
        font-size: 40px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 60px;
        left: 68%;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 55px;
    }
    .whatsNew-cloud p.item-17 {
        font-size: 31px;
    }
    .whatsNew-cloud p.item-18 {
        left: 76%;
    }
    .whatsNew-cloud p.item-20 {
        left: 27%;
        font-size: 40px;
    }
    .whatsNew-cloud p.item-23 {
        left: 58%;
    }
    .whatsNew-cloud p.item-24 {
        left: 43%;
    }
}
@media only screen and (max-width: 1250px) {
    .whatsNew {
        padding-top: 0;
    }
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 62%;
    }
    .cloud_react-logo {
        left: 50%;
    }
    
    .whatsNew-cloud p.item-1 {
        left: 15px;
    }

    .whatsNew-cloud p.item-3 {
        left: 3%;
    }

    .whatsNew-cloud p.item-6 {
        font-size: 40px;
        left: 65%;
    }

    .whatsNew-cloud p.item-5 {
        font-size: 40px;
        left: 54px;
    }

    .whatsNew-cloud p.item-7 {
        left: 0%;
    }

    .whatsNew-cloud p.item-8 {
        left: 54%;
    }

    .whatsNew-cloud p.item-10 {
        font-size: 45px;
        left: 40px;
    }

    .whatsNew-cloud p.item-11 {
        left: 73%;
    }

    .whatsNew-cloud p.item-13 {
        font-size: 55px;
        left: 75%;
    }

    .whatsNew-cloud p.item-15 {
        font-size: 45px;
        left: 50%;
    }

    .whatsNew-cloud p.item-19 {
        left: 3%;
    }

    .whatsNew-cloud p.item-21 {
        left: 64%;
    }
}
@media only screen and (max-width: 1100px) {
    .cloud_react-logo {
        left: 36%;
        width: 60px;
        top: 20px;
    }
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 46%;
    }
    .whatsNew .whatsNew-title h2 {
        font-size: 35px;
        top: 25px;
    }
    .whatsNew-cloud p.item-1 {
        font-size: 30px;
        left: 50px;
    }
    .whatsNew-cloud p.item-2 {
        left: 58%;
    }
    .whatsNew-cloud p.item-3 {
        left: 9%;
        font-size: 18px;
    }
    .whatsNew-cloud p.item-4 {
        font-size: 20px;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 35px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 40px;
        left: 57%;
    }
    .whatsNew-cloud p.item-7 {
        font-size: 30px;
        left: 6%;
    }
    .whatsNew-cloud p.item-8 {
        left: 47%;
    }
    .whatsNew-cloud p.item-9 {
        font-size: 25px;
        left: 70%;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 38px;
        left: 70px;
    }
    .whatsNew-cloud p.item-12 {
        left: 8%;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 50px;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 37px;
        left: 53%;
    }
    .whatsNew-cloud p.item-17 {
        font-size: 20px;
        left: 40%;
    }
    .whatsNew-cloud p.item-19 {
        font-size: 20px;
    }
    .whatsNew-cloud p.item-20 {
        font-size: 30px;
        left: 30%;
    }
    .whatsNew-cloud p.item-22 {
        left: 15%;
    }
    .whatsNew-cloud p.item-23 {
        left: 47%;
    }
}
@media only screen and (max-width: 991px) {
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 62%;
    }
    .cloud_react-logo {
        left: 48%;
    }
}
@media only screen and (max-width: 900px) {
    .whatsNew-cloud p.item-1 {
        font-size: 25px;
    }
    .whatsNew-cloud p.item-2 {
        top: 15px;
        left: 56%;
    }
    .whatsNew-cloud p.item-4 {
        top: 44px;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 30px;
        left: 50px;
    }
    .whatsNew-cloud p.item-7 {
        top: 150px;
    }
    .whatsNew-cloud p.item-8 {
        left: 41%;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 30px;
        left: 30px;
    }
    .whatsNew-cloud p.item-11 {
        font-size: 20px;
        left: 69%;
    }
    .whatsNew-cloud p.item-12 {
        font-size: 30px;
        left: 6%;
        top: 280px;
    }
    .whatsNew-cloud p.item-13 {
        left: 62%;
    }
    .whatsNew-cloud p.item-14 {
        top: 330px;
    }
    .whatsNew-cloud p.item-15 {
        top: 380px;
        left: 40%;
    }
    .whatsNew-cloud p.item-17 {
        top: 456px;
        left: 28%;
    }
    .whatsNew-cloud p.item-18 {
        font-size: 20px;
        left: 70%;
    }
    .whatsNew-cloud p.item-20 {
        font-size: 25px;
    }
    .whatsNew-cloud p.item-22 {
        left: 5%;
    }
    .whatsNew-cloud p.item-23 {
        left: 41%;
    }
    .whatsNew-cloud p.item-24 {
        left: 13%;
    }
}
@media only screen and (max-width: 761px) {
    .whatsNew .whatsNew-title h2 {
        font-size: 32px;
    }

    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 69%;
    }
    .cloud_react-logo {
        left: 56%;
        width: 50px;
    }
}
@media only screen and (max-width: 750px) {
    .whatsNew-cloud p.item-1 {
        font-size: 20px;
    }
    .whatsNew-cloud p.item-2 {
        font-size: 16px;
        top: 18px;
    }
    .whatsNew-cloud p.item-3 {
        font-size: 15px;
    }
    .whatsNew-cloud p.item-4 {
        font-size: 17px;
        left: 44%;
    }
    .whatsNew-cloud p.item-5 {
        font-size: 25px;
        top: 115px;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 30px;
    }
    .whatsNew-cloud p.item-9 {
        font-size: 18px;
        top: 140px;
    }
    .whatsNew-cloud p.item-11 {
        top: 260px;
    }
    .whatsNew-cloud p.item-12 {
        font-size: 25px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 40px;
        top: 280px;
    }
    .whatsNew-cloud p.item-14 {
        font-size: 30px;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 30px;
    }
    .whatsNew-cloud p.item-17 {
        left: 16%;
    }
    .whatsNew-cloud p.item-19 {
        top: 495px;
    }
    .whatsNew-cloud p.item-20 {
        top: 495px;
        left: 35%;
    }
    .whatsNew-cloud p.item-21 {
        font-size: 16px;
        top: 535px;
    }
    .whatsNew-cloud p.item-23 {
        font-size: 25px;
        top: 570px;
        left: 52%;
    }
    .whatsNew-cloud p.item-24 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 600px) {
    .whatsNew-cloud p.item-2 {
        left: 40%;
        top: 26px;
    }
    .whatsNew-cloud p.item-4 {
        left: 28%;
    }
    .whatsNew-cloud p.item-6 {
        font-size: 25px;
        left: 50%;
    }
    .whatsNew-cloud p.item-7 {
        font-size: 25px;
        top: 167px;
    }
    .whatsNew-cloud p.item-9 br {
        display: none;
    }
    .whatsNew-cloud p.item-9 {
        left: 50%;
        top: 146px;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 25px;
    }
    .whatsNew-cloud p.item-11 {
        left: 51%;
        top: 253px;
    }
    .whatsNew-cloud p.item-12 {
        font-size: 22px;
    }
    .whatsNew-cloud p.item-13 {
        font-size: 25px;
        top: 305px;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 22px;
    }
    .whatsNew-cloud p.item-18 {
        font-size: 15px;
        top: 410px;
    }
    .whatsNew-cloud p.item-20 {
        font-size: 20px;
        left: 50%;
    }
    .whatsNew-cloud p.item-21 {
        font-size: 12px;
    }
    .whatsNew-cloud p.item-23 {
        font-size: 18px;
        top: 583px;
    }
    .whatsNew-cloud p.item-24 {
        font-size: 25px;
        left: 7%;
    }
}
@media only screen and (max-width: 575px) {
    .whatsNew .whatsNew-title h2 {
        font-size: 25px;
    }

    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 60%;
    }
    .cloud_react-logo {
        left: 47%;
        width: 40px;
    }
}
@media only screen and (max-width: 510px) {
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 66%;
    }
    .cloud_react-logo {
        left: 50%;
    }
}
@media only screen and (max-width: 469px) {
    .cloud_react-logo {
        left: 53%;
        width: 35px;
    }
}
@media only screen and (max-width: 450px) {
    .whatsNew .whatsNew-title h2 {
        font-size: 20px;
    }
    .cloud_react-logo {
        left: 49%;
    }
    .whatsNew-cloud p.item-1 {
        left: 10px;
    }
    .whatsNew-cloud p.item-2 {
        left: 23%;
    }
    .whatsNew-cloud p.item-3 {
        left: 6%;
    }
    .whatsNew-cloud p.item-4 {
        left: 17%;
    }
    .whatsNew-cloud p.item-5 {
        left: 30px;
        font-size: 20px;
    }
    .whatsNew-cloud p.item-6 {
        left: 35%;
    }
    .whatsNew-cloud p.item-7 {
        font-size: 20px;
    }
    .whatsNew-cloud p.item-9 {
        left: 31%;
    }
    .whatsNew-cloud p.item-10 {
        font-size: 22px;
    }
    .whatsNew-cloud p.item-11 {
        left: 60%;
    }
    .whatsNew-cloud p.item-12 {
        top: 315px;
    }
    .whatsNew-cloud p.item-13 {
        top: 280px;
        left: 14%;
    }
    .whatsNew-cloud p.item-14 {
        font-size: 25px;
        top: 347px;
    }
    .whatsNew-cloud p.item-15 {
        font-size: 18px;
        top: 385px;
    }
    .whatsNew-cloud p.item-16 {
        font-size: 20px;
    }
    .whatsNew-cloud p.item-17 {
        font-size: 15px;
    }
    .whatsNew-cloud p.item-18 {
        left: 60%;
    }
    .whatsNew-cloud p.item-23 {
        left: 33%;
    }
}
@media only screen and (max-width: 450px) {
    .cloud_react-logo {
        left: 54%;
        width: 30px !important;
        top: 25px;
    }

    .whatsNew-cloud p.item-1 {
        left: 0;
        font-size: 17px;
    }

    .whatsNew-cloud p.item-2 {
        left: 39%;
        top: 22px;
        font-size: 13px;
    }

    .whatsNew-cloud p.item-3 {
        left: 12%;
        top: 58px;
    }

    .whatsNew-cloud p.item-4 {
        left: 7%;
        top: 37px;
    }

    .whatsNew-cloud p.item-6 {
        left: 55%;
        top: 75px;
        font-size: 22px;
    }

    .whatsNew-cloud p.item-5 {
        left: -5px;
        font-size: 15px;
        top: 85px;
    }

    .whatsNew-cloud p.item-7 {
        font-size: 18px;
        top: 132px;
        left: 3%;
    }

    .whatsNew-cloud p.item-8 {
        left: 0;
        font-size: 15px;
        top: 105px;
    }

    .whatsNew-cloud p.item-9 {
        left: 45%;
        top: 107px;
        font-size: 16px;
    }

    .whatsNew-cloud p.item-10 {
        top: 160px;
        font-size: 24px;
        left: 0%;
    }

    .whatsNew-cloud p.item-15 {
        font-size: 12px;
        top: 136px;
        left: 63%;
    }

    .whatsNew-cloud p.item-11 {
        left: 50%;
        font-size: 20px;
        top: 200px;
    }

    .whatsNew-cloud p.item-12 {
        top: 225px;
    }

    .whatsNew-cloud p.item-14 {
        top: 254px;
        left: 2%;
        font-size: 18px;
    }

    .whatsNew-cloud p.item-13 {
        top: 188px;
    }

    .whatsNew-cloud p.item-23 {
        left: 57%;
        top: 257px;
        font-size: 15px;
    }

    .whatsNew-cloud p.item-16 {
        top: 283px;
    }

    .whatsNew-cloud p.item-18 {
        top: 280px;
    }

    .whatsNew-cloud p.item-17 {
        font-size: 22px;
        top: 326px;
        left: 7%;
    }

    .whatsNew-cloud p.item-19 {
        top: 359px;
    }

    .whatsNew-cloud p.item-20 {
        font-size: 22px;
        top: 368px;
    }

    .whatsNew-cloud p.item-22 {
        top: 420px;
        left: 5%;
        font-size: 25px;
    }

    .whatsNew-cloud p.item-21 {
        font-size: 12px;
        top: 400px;
        left: 53%;
    }

    .whatsNew-cloud {
        height: 400px;
    }

    .whatsNew .whatsNew-title {
        padding: 80px 0 10px;
    }
}
@media only screen and (max-width: 385px) {
    .cloud_react-logo {
        left: 59%;
    }
    .whatsNew .whatsNew-title h2:nth-child(2) {
        left: 72%;
    }

    .whatsNew-cloud p.item-2 {
        left: 27%;
    }

    .whatsNew-cloud p.item-3 {
        left: -5%;
    }

    .whatsNew-cloud p.item-6 {
        left: 60%;
        font-size: 16px;
    }

    .whatsNew-cloud p.item-9 {
        font-size: 13px;
    }

    .whatsNew-cloud p.item-7 {
        font-size: 16px;
        left: -3%;
    }

    .whatsNew-cloud p.item-15 {
        font-size: 9px;
    }

    .whatsNew-cloud p.item-10 {
        font-size: 19px;
    }

    .whatsNew-cloud p.item-13 {
        font-size: 22px;
        left: 1%;
    }

    .whatsNew-cloud p.item-11 {
        left: 38%;
    }

    .whatsNew-cloud p.item-12 {
        left: 3%;
        font-size: 20px;
    }

    .whatsNew-cloud p.item-23 {
        font-size: 12px;
    }

    .whatsNew-cloud p.item-14 {
        left: 0;
        font-size: 16px;
    }

    .whatsNew-cloud p.item-16 {
        left: 3%;
    }

    .whatsNew-cloud p.item-17 {
        font-size: 20px;
        left: -2%;
    }

    .whatsNew-cloud p.item-20 {
        font-size: 18px;
    }

    .whatsNew-cloud p.item-21 {
        font-size: 9px;
        left: 57%;
    }

    .whatsNew-cloud p.item-22 {
        left: 6px;
        font-size: 23px;
    }
}
