.news {
    background-color: black;
    font-family: "SpaceMono";
}
.news p {
    margin-bottom: 0;
}

.news_title_block {
    margin-bottom: 40px;
}