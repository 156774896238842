.pc_wrap {
    background-color: black;
    font-family: 'SpaceMono',Arial,sans-serif;
}

.pc {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.pc_item {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.pc_item_photo {
    max-width: 162px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
    margin-bottom: 20px;
    transition: .7s;
    -webkit-filter: grayscale(99%);  
    filter: grayscale(99%); 
}

.pc_item_photo:hover {
    -webkit-filter: grayscale(0%);  
    filter: grayscale(0%);  
}

.pc_item_logo {
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    object-fit: contain;
}

.pc_item_name {
    color: #61dbfb;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.pc_item_position {
    color: #dbdbdb;
    font-size: 16px;
}

.pc_info {
    margin-bottom: 10px;
    max-width: 260px;
    min-height: 130px;
}

.links_block {
    display: flex;
}

.pc_link--ln {
    background-image: url('../../img/ln_white.svg');
}

.pc_link--twitter {
    background-image: url('../../img/twitter_white.svg');
}

.pc_link {
    margin-right: 18px;
}

.pc_link:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1300px) {
    .pc_item {
        width: calc(100% / 3);
    }

    .pc_info {
        max-width: 200px;
        min-height: 100px;
    }

    .pc_item_name {
        font-size: 20px;
    }

    .pc_item_position {
        height: 50px;
    }
}

@media screen and (max-width: 640px) {
    .pc_item {
        width: calc(100% / 2);
        padding: 0 10px;
    }

    .pc_item_photo {
        max-width: 110px;
    }

    .pc_item_name {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .pc_item_position {
        font-size: 14px;
        height: 40px;
    }

    .link.pc_link {
        margin-right: 12px;
    }

    .pc_item_logo {
        max-height: 70px;
    }
}